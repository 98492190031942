import { createSlice } from "@reduxjs/toolkit";

export const inicialEspValores = {
  params: {
    limit: 20,
    page: 1,
    state: "sp",
  },
};

const initialState = () => {
  return inicialEspValores;
};

export const EspValoresSlice = createSlice({
  name: "espValores",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      if (payload?.value === "" || payload?.value === null)
        delete state.params[field];
      return state;
    },
  },
});

export const { handleParams } = EspValoresSlice.actions;

export default EspValoresSlice.reducer;
