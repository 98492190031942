import React from "react";
import { Label } from "reactstrap";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { Format } from "utils";

import "@styles/react/libs/flatpickr/flatpickr.scss";
import "flatpickr/dist/plugins/monthSelect/style.css";

import { Controller } from "react-hook-form";
import { registerLocale } from "react-datepicker";

import { useSkin } from "@hooks/useSkin";

import ptBR from "date-fns/locale/pt-BR";

import * as S from "./styles";

registerLocale("ptBR", ptBR);

export const DatePickerRange = ({
  control,
  name,
  label,
  placeholder,
  ...rest
}) => {
  const { skin } = useSkin();

  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <Label>{label}</Label>
            <S.CustomFlatpickr
              options={{
                disableMobile: true,
                locale: Portuguese,
                format: "Y-m-d",
                mode: "range",
              }}
              style={{
                color: "#7367f0",
              }}
              className="form-control"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              skin={skin}
            />
          </>
        )}
      />
    );
  }

  return (
    <>
      <Label>{label}</Label>
      <S.CustomFlatpickr
        options={{
          locale: Portuguese,
          dateFormat: "Y-m-d",
          mode: "range",
          formatDate: valueDate => {
            return valueDate > 0
              ? Format(valueDate, "dd/MM/yyyy")
              : "Carregando data...";
          },
        }}
        {...rest}
        className="form-control"
        placeholder={placeholder}
        skin={skin}
      />
    </>
  );
};

export const DatePicker = ({
  control,
  name,
  label,
  placeholder,
  type = "default" || "range",
  required = false,
  configs,
  ...props
}) => {
  const { skin } = useSkin();

  const configOptions = {
    disableMobile: configs?.disableMobile || true,
    locale: Portuguese,
    format: configs?.format || "Y-m-d",
    ...configs,
  };
  if (type === "range") {
    return (
      <DatePickerRange
        control={control}
        name={name}
        label={label}
        placeholder={placeholder}
        skin={skin}
      />
    );
  }

  return (
    <Controller
      required={required}
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <Label>{label}</Label>
          <S.CustomFlatpickr
            options={configOptions}
            style={{
              color: "#7367f0",
            }}
            className="form-control"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            {...props}
            skin={skin}
          />
        </>
      )}
    />
  );
};

export * from "./DatePickerRound";

export * from "./DatePickerMonth";
