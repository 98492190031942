import { useCallback } from "react";
import { useFetchMixAPi1, useMixApi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useCategoriasList = (params = {}) =>
  useFetchMixAPi1(
    getURLWithParams(`/legislacao/categorias-tributacao`, params),
  );

export const useCategoriasDetail = id =>
  useFetchMixAPi1(id ? `/legislacao/categorias-tributacao/detail/${id}` : null);

export const useCasosComNotaFiscal = id =>
  useFetchMixAPi1(`legislacao/casos-especiais/associacao/notafiscal/${id}`);

export const useCategoriasAsync = () => {
  const api = useMixApi1();

  const fetchEspecificacoes = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/especificacoes/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchCasosEspeciais = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/casos-especiais/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );

  const fetchNotasFiscais = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/nota-fiscal/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchSetores = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/setores/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchSped = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams(
            "/legislacao/regras-tributarias/sped/search",
            params,
          ),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchCest = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/cest/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchMercado = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/mercadosubgrupo/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );
  const fetchPisCofins = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/legislacao/piscofins/search", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        return [];
      }
    },
    [api],
  );

  return {
    fetchEspecificacoes,
    fetchCasosEspeciais,
    fetchNotasFiscais,
    fetchSetores,
    fetchSped,
    fetchCest,
    fetchMercado,
    fetchPisCofins,
  };
};

export const useCategoriasToast = (id = null) => {
  const { onSend } = useMixApi1Toast();

  const onCreate = async data => {
    const endpoint = id
      ? `legislacao/categorias-tributacao/update/${id}`
      : `legislacao/categorias-tributacao/novo`;
    const type = id ? "PUT" : "POST";

    return onSend({
      endpoint,
      messageSuccess: id ? "Atualizado" : `Regra Cadastrada com Sucesso`,
      messageFail: id
        ? "Falha ao atualizar"
        : `Não foi possível cadastrar a Regra`,
      data,
      type,
      redirect: true,
      fieldGetId: id ? "" : "mxf_cat_id",
      directId: id ?? "",
      urlRedirect: "/legislacao/categorias/editar",
    });
  };

  const onRemove = async () => {
    const endpoint = `legislacao/categorias-tributacao/delete/${id}`;
    const type = "DELETE";

    return onSend({
      endpoint,
      messageSuccess: "Categoria deleta com sucesso",
      messageFail: "Não foi possível deletar a categoria",
      type,
    });
  };

  const onRemoveNotaFiscal = async data => {
    const endpoint = `legislacao/notas-categorias/delete`;
    const type = "DELETE";

    return onSend({
      endpoint,
      messageSuccess: "Nota Fiscal removida com sucesso",
      messageFail: "Não foi possível remover a Nota Fiscal",
      data,
      type,
    });
  };

  const onRemoveCasosEspeciais = async data => {
    const endpoint = `legislacao/casos-categorias/delete`;
    const type = "DELETE";

    return onSend({
      endpoint,
      messageSuccess: "Caso Especial removido com sucesso",
      messageFail: "Não foi possível remover o Caso Especial",
      data,
      type,
    });
  };

  const onRemoveEspecificacao = async data => {
    const endpoint = `legislacao/especificacao-categoria/delete/${id}`;
    const type = "DELETE";

    return onSend({
      endpoint,
      messageSuccess: "Especificação removida com sucesso",
      messageFail: "Não foi possível remover a Especificação",
      data,
      type,
    });
  };

  return {
    onCreate,
    onRemove,
    onRemoveNotaFiscal,
    onRemoveCasosEspeciais,
    onRemoveEspecificacao,
  };
};
