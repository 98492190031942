import { createSlice } from "@reduxjs/toolkit";

export const initialHeatMap = {
  geoMap: {},
  params: {
    limit: 8,
    page: 1,
  },
  paramsSupplierGroup: {
    limit: 15,
    page: 1,
  },
  paramsSupplierGroupSearch: {},
  paramsListProducts: {
    limit: 5,
    page: 1,
  },
  paramsPerState: {
    limit: 8,
    page: 1,
    state: "",
  },
  tab: "all",
  estadosPorRegiao: {},
  isShowModal: false,
  modal: {
    show: false,
  },
  paramsModalListProducts: {
    limit: 5,
    page: 1,
  },
};

const initialState = () => {
  return initialHeatMap;
};

export const heatMapSlice = createSlice({
  name: "heatMap",
  initialState,
  reducers: {
    handleSetGeo: (state, { payload }) => {
      state.geoMap = payload?.map;
      state.tab = payload?.tab;
      state.paramsPerState.limit = 8;
      state.paramsPerState.page = 1;
      state.paramsPerState.state = payload.map?.properties?.id;
    },
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.paramsPerState[field] = payload?.value;
      return state;
    },
    handleParamsAll: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleParamsGroupClear: state => {
      delete state.paramsSupplierGroupSearch.emit_cnpj;
      delete state.paramsSupplierGroupSearch.emit_razao_social;
      delete state.paramsSupplierGroupSearch.order;
      state.paramsSupplierGroupSearch = {};
      return state;
    },
    handleParamsGroup: (state, { payload }) => {
      const field = payload?.field;
      state.paramsSupplierGroup[field] = payload?.value;
      return state;
    },
    handleParamsGroupSearch: (state, { payload }) => {
      const field = payload?.field;
      state.paramsSupplierGroupSearch[field] = payload?.value;
      return state;
    },
    handleParamsListProducts: (state, { payload }) => {
      const field = payload?.field;
      state.paramsListProducts[field] = payload?.value;
      return state;
    },
    handleSetTab: (state, { payload }) => {
      state.tab = payload;
    },
    handleSetEstadosPorRegiao: (state, { payload }) => {
      state.estadosPorRegiao = payload;
    },
    handleOpenModal(state, { payload }) {
      state.modal = payload;
      state.paramsModalListProducts.page = 1;
    },
    handleParamsModalListProducts: (state, { payload }) => {
      const field = payload?.field;
      state.paramsModalListProducts[field] = payload?.value;
      return state;
    },
  },
});

export const {
  handleSetGeo,
  handleSetTab,
  handleParams,
  handleParamsAll,
  handleSetEstadosPorRegiao,
  handleParamsGroupSearch,
  handleParamsGroupClear,
  handleParamsGroup,
  handleParamsListProducts,
  handleOpenModal,
  handleParamsModalListProducts,
} = heatMapSlice.actions;

export default heatMapSlice.reducer;
