import { useCallback } from "react";
import { useMixApi1, useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useFetchTotaisClassifications = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/produtos-classificar/totais", params),
  );

export const useFetchMotivosClassificar = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/classificar/motivos", params),
  );

export const useFetchSetoresList = params =>
  useFetchMixAPi1(getURLWithParams("/classificacao/filtros", params));

export const useFetchProdutosClassifications = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/produtos-classificar", params),
  );

export const useFetchProdutosVinculadosClassifications = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/produtos-classificar/vinculados", params),
  );

export const useFetchProdutosSimilaridade = params =>
  useFetchMixAPi1(
    getURLWithParams(
      "/classificacao/classificacao-produtos-similaridade",
      params,
    ),
  );
export const useFetchProdutosVinculadosClassificationsModal = params =>
  useFetchMixAPi1(
    getURLWithParams(
      "/classificacao/produtos-classificar/vinculados/show",
      params,
    ),
  );

export const useFetchProdutoShowInfo = params =>
  useFetchMixAPi1(getURLWithParams("/classificacao/produto/info", params));

export const useFetchProdutoShowInfoLogs = params =>
  useFetchMixAPi1(getURLWithParams("/classificacao/produto/info/logs", params));

export const useFetchProdutoShowCat = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/produto/icms/categoria", params),
  );

export const useFetchProdutoShowInfoIcms = params =>
  useFetchMixAPi1(getURLWithParams("/classificacao/produto/info-icms", params));

export const useFetchProdutoShowClassification = params =>
  useFetchMixAPi1(
    getURLWithParams("/classificacao/produto/classificacao", params),
  );
export const useFetchAllSector = params =>
  useFetchMixAPi1(getURLWithParams("/classificacao/filtros", params));

export const useFetchFilterClassification = () => {
  const api = useMixApi1();

  const fetchFilter = useCallback(
    async (params = {}) => {
      try {
        const response = await api.get(
          getURLWithParams("/classificacao/filtros", params),
        );

        const data = response.data || [];
        return data;
      } catch (error) {
        console.error("Error fetching stores:", error);
        return [];
      }
    },
    [api],
  );

  return {
    fetchFilter,
  };
};

export const useActionClassification = () => {
  const { onSend, isLoading } = useMixApi1Toast();

  return {
    isLoading,
    onClassificationProducts: data =>
      onSend({
        endpoint: "/classificacao/classifica-produto",
        data,
        messageFail: "Falha ao tentar classificar produto",
        messageSuccess: "Produto classificado com sucesso!",
      }),
    onClassificationMoveProductClient: data =>
      onSend({
        endpoint: "/classificacao/mover-produto-cliente",
        data,
        messageFail: "Falha ao tentar classificar produto",
        messageSuccess: "Produto movido com sucesso!",
      }),
  };
};
