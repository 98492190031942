import React, { useState } from "react";
import { ChevronRight, Plus } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";

import * as S from "./DataTable-style";

const PagesHeaders = props => {
  const {
    isOpen,
    isToggle,
    title,
    icon,
    isButtonAdd,
    handleActionLink,
    isToggleAction = false,
    fields,
    isTooltip = false,
    isShowFilterField = false,
    isFirstChildrenAction = false,
    classNameFix = "mt-1 mb-1 pt-1 px-1 pb-1",
    childrenTitle = ``,
    filterQtd = [],
  } = props;

  const [toggle, setToggle] = useState(isOpen);

  const navigate = useNavigate();

  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <Card className={classNameFix}>
      <Row>
        <Col
          md="7"
          xs="6"
          className="d-flex align-items-center"
          onClick={() => isToggle && handleToggle()}
        >
          <Row>
            <Col md="12">
              <h4 className="m-0 p-0 fw-bolderer">
                {icon ? <>{icon} - </> : ""}
                {title}
              </h4>
              {childrenTitle || ""}
            </Col>
            {!toggle && isShowFilterField && Object.keys(fields)?.length > 0 ? (
              <Col md="12">
                <h5>
                  Filtrando por:&nbsp;
                  {Object.keys(fields)?.length > 0
                    ? Object.keys(fields).map((item, key) => {
                        return (
                          <p key={key}>
                            <Badge color="primary">{fields[item]}</Badge>
                            {key >= 0 && Object.keys(fields)?.length !== 0 ? (
                              <ChevronRight size={11} />
                            ) : null}
                          </p>
                        );
                      })
                    : null}
                </h5>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col md="5" xs="6" className="text-end">
          <S.FlexAction isButtonAdd={isButtonAdd || props?.childrenAction}>
            {isFirstChildrenAction && props?.childrenAction}

            {/** TODO: Alterar as outras interfaces que usam esse botão para que o feedback visual funcione corretamente. */}
            {isToggle && (
              <Button
                onClick={() => isToggle && handleToggle()}
                className={isButtonAdd || (props?.childrenAction && "mx-1")}
                size="sm"
              >
                {props?.titleFilter || "FILTRO"}
                {filterQtd.length > 0 &&
                  ` | ${
                    filterQtd?.filter(filter => filter?.length > 1)?.length
                  }`}
              </Button>
            )}
            {isButtonAdd || isToggleAction ? (
              <>
                {!isTooltip && (
                  <UncontrolledTooltip placement="top" target="add">
                    {props?.titleToolTip}
                  </UncontrolledTooltip>
                )}
                <Button
                  onClick={() => {
                    if (isToggleAction) {
                      return handleActionLink();
                    }
                    return navigate(handleActionLink);
                  }}
                  className="ms-1"
                  color="primary"
                  size="sm"
                  id="add"
                >
                  {props?.titleButtonAdd ? (
                    props?.titleButtonAdd
                  ) : (
                    <Plus size={14} />
                  )}
                </Button>
              </>
            ) : null}
            {!isFirstChildrenAction && props?.childrenAction}
          </S.FlexAction>
        </Col>
      </Row>

      {props?.childrenToogle ? (
        <Row>
          <Col
            md={{
              size: "12",
            }}
            xs={{
              size: "12",
            }}
          >
            <Collapse isOpen={toggle}>{props?.childrenToogle}</Collapse>
          </Col>
        </Row>
      ) : null}

      {props?.childrenPrefix ? (
        <Row>
          <Col
            md={{
              size: "12",
            }}
            xs={{
              size: "12",
            }}
          >
            <Collapse isOpen={!toggle}>{props?.childrenPrefix}</Collapse>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

PagesHeaders.AppProps = {
  isOpen: Boolean,
  title: String || JSX.Element || [],
  isToggle: Boolean,
  isButtonAdd: Boolean,
  titleButtonAdd: String,
  titleToolTip: String,
  handleActionLink: String,
  childrenTitle: String || JSX.Element || [],
  isToggleAction: Boolean,
  titleFilter: String || JSX.Element,
  childrenAction: String || JSX.Element || [],
  childrenToogle: String || JSX.Element || [],
  childrenPrefix: String || JSX.Element || [],
  fields: String || JSX.Object || [],
  isFirstChildrenAction: Boolean,
  isShowFilterField: Boolean,
  isTooltip: Boolean,
};

export default PagesHeaders;
